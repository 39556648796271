
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useOrg } from "hooks/useOrg";

import { TopNav } from "components/top-nav";
import { Footer } from "components/footer";

import ForRegister from "pages/for/for-register";

import { Org } from "types";


const ForPage = () => { 
  const { orgUrlName } = useParams<{ orgUrlName: string }>();
  const orgService = useOrg();
  const [ org, setOrg ] = useState<Org | undefined>(undefined);
  const loading = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getOrg(orgUrlName : string) {
      if (loading.current) return;
      loading.current = true;

      try {
        const newOrg = await orgService.getOrgByUrlName(orgUrlName);
        if (!newOrg) navigate('/');

        setOrg(newOrg);
      } catch (err) {
        navigate('/');
      }
    }
 
    getOrg(orgUrlName!);
  });

  /*
    <Typography variant="h4" style={{ marginTop:'10px'}}>
      This is the for page for {org?.displayName}.
    </Typography>
  */
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <TopNav orgName={org?.displayName} />

      <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', height: '100vh' }}>
        <ForRegister urlName={org?.urlName} shortName={org?.shortName} />
      </div>
  
      <Footer />
    </div>
  );
}

export default ForPage;
