
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function StartupPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
        try {
            const authStorage = localStorage.getItem('authState');
            const authState = (authStorage ? JSON.parse(authStorage) : { userId: '', profileId: '', authToken: '', roles: [] });

            if (authState.authToken !== '') {
                switch(authState.roles[0]) {
                    case 'admin':
                        window.location.href = '/admin';
                        break;
                    case 'user':
                        window.location.href = '/support';
                        break;
                    case 'coach':
                        window.location.href = '/coach';
                        break;
                    default:
                        window.location.href = '/login';
                    break;
                }
            } else {
                navigate('/auth/login');
            }
        } catch (err) {
            localStorage.removeItem('authState');
            navigate('/auth/login');
        }
    }, [ navigate ]);

    return null;
}
