
import { useState } from 'react';

import axios, { AxiosError } from 'axios';

import { config } from 'config';
import { AuthError, AuthResponse, Invite } from 'types';


interface Auth {
    error: AuthError | null;

    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    register: (firstName: string, lastName: string, username: string, password: string) =>  Promise<void>;
    registerForOrg: (orgId: string, firstName: string, lastName: string, username: string, password: string) => Promise<void>;

    getInvite: (inviteId: string) =>  Promise<Invite|undefined>;
    acceptInvite: (inviteId: string, firstName: string, lastName: string, username: string, password: string) =>  Promise<void>;
}

export function useAuth(): Auth {
  const [ error, setError ] = useState<AuthError | null>(null);

  const api = axios.create({
    baseURL: config().apiBaseUrl,
    headers: {
      "Content-type": "application/json"
    }
  });

  const processError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      setError({ message: axiosError.message });
    } else {
      setError({ message: 'An error occurred in the auth service.' });
    }

    console.log('authService.error:', error);
    throw error;
  }

  const setupAuthState = (auth: AuthResponse) => {
    const authState = {
      authToken: auth.access_token,
      userId: auth.userId,
      profileId: auth.profileId,
      profileName: auth.profileName,
      roles: auth.roles
    };

    localStorage.setItem('authState', JSON.stringify(authState));
  }

  
  const login = async (username: string, password: string): Promise<void> => {
    setError(null);

    try {
      const response = await api.post<AuthResponse>(`auth/signin`, { username,  password, });

      setupAuthState(response.data);
    } catch (error: unknown) {
      processError(error);
    }
  };

  const logout = (): void => {
    // Clear Session
    localStorage.removeItem('authState');
  };

  const register = async (firstName: string, lastName: string, username: string, password: string): Promise<void> => {
    setError(null);

    try {
      const imageUrl = ''
      const response = await api.post<AuthResponse>(`auth/signup`,
        { username, password, firstName, lastName, imageUrl }
      );

      setupAuthState(response.data);
    } catch (error: unknown) {
      processError(error);
    }
  };

  const registerForOrg = async (orgId: string, firstName: string, lastName: string, username: string, password: string): Promise<void> => {
    setError(null);

    try {
      const imageUrl = '';
      const response = await api.post<AuthResponse>(`auth/onboard`,
        { username, password, firstName, lastName, imageUrl, orgId  }
      );

      setupAuthState(response.data);
    } catch (error: unknown) {
      processError(error);
    }
  }

  const getInvite = async (inviteId: string): Promise<Invite|undefined> => {
    setError(null);

    try {
      const response = await api.get<Invite>(`auth/invite/${inviteId}`);

      return response.data;
    } catch (error: unknown) {
      processError(error);
    }

    return undefined;
  };

  const acceptInvite = async (inviteId: string, firstName: string, lastName: string, username: string, password: string): Promise<void> => {
    setError(null); 

    try {
      const response = await api.post<AuthResponse>(`auth/accept`,
        { inviteId, firstName, lastName, username, password }
      );

      setupAuthState(response.data);
    } catch (error: unknown) {
      processError(error);
    }
  };


  return { login, logout, register, registerForOrg, getInvite, acceptInvite, error };
}
