import { ReactNode, createContext, useState } from 'react';

interface AuthContextInterface {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  loggedIn: false,
  setLoggedIn: () => {},
});

interface AuthProviderProps {
    children: ReactNode;
  }
  
export const AuthProvider = ({ children } : AuthProviderProps ) => {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}
