import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        {new Date().getFullYear()}
        {' '}
        <Link color="inherit" to="/">
          OurKeel.com
        </Link>
      </Typography>
    );
  }
  