
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

export default function LogoutPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    
    useEffect(() => {
        auth.logout();

        navigate('/auth/login');
    }, [ navigate, auth ]);

    return null;
}
