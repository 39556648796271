

export function LargeOutlinedButton({ ...props }) {
    const { text, onClick } = props;
  
    return (
      <button 
        style={{
          backgroundColor: 'transparent',
          border: '1px solid #3F4A49',
          borderRadius: '5px',
          color: '#3F4A49',
          padding: '8px 20px',
          fontSize: '1.0rem',
          fontWeight: 'normal',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        {text}
      </button>
    );
}
