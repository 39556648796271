import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from 'shared/auth';

import StartupPage from 'pages/startup-page';
import NotFoundPage from 'pages/not-found-page';

import LoginPage from 'pages/login-page';
import LogoutPage from 'pages/logout-page';

import RegisterPage from 'pages/register-page';
import InvitePage from 'pages/invite-page';

import ForPage from 'pages/for/for-page';

import './app.css';

export const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/"                       element={<StartupPage />} />

          <Route path="/auth/login"             element={<LoginPage />} />
          <Route path="/auth/logout"            element={<LogoutPage />} />

          <Route path="/auth/register"          element={<RegisterPage />} />
          <Route path="/auth/invite/:inviteId"  element={<InvitePage />} />

          <Route path="/for/:orgUrlName"        element={<ForPage />} />

          <Route path="*"                       element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};
