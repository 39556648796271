import devConfig from './config-dev.json';
import prodConfig from './config-prod.json';
import localConfig from './config-local.json';
  
const config = () => {
  const env = process.env.NODE_ENV || 'development';

  switch (env) {
    case 'production':
      return prodConfig;
    case 'local':
      return localConfig;
    case 'development':
      return devConfig;
      
    default:
      return devConfig;
  }
};

export default config;
