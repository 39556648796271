
import { useState } from 'react';

import axios, { AxiosError } from 'axios';

import { config } from 'config';
import { Org, OrgError } from 'types';


interface Orgs {
    error: OrgError | null;

    getOrgByUrlName: (orgUrlName: string) => Promise<Org|undefined>;
}

export function useOrg(): Orgs {
  const [ error, setError ] = useState<OrgError | null>(null);

  const api = axios.create({
    baseURL: config().apiBaseUrl,
    headers: {
      "Content-type": "application/json"
    }
  });

  const processError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      setError({ message: axiosError.message });
    } else {
      setError({ message: 'An error occurred in the org service.' });
    }

    console.log('orgService.error:', error);
    throw error;
  }

  const getOrgByUrlName = async (orgUrlName: string): Promise<Org|undefined> => {
    setError(null);

    try {
      const response = await api.get<Org>(`orgs/${orgUrlName}`, { params: { orgUrlName } });

      return response.data;
    } catch (error: unknown) {
      processError(error);
    }
  };

  return { getOrgByUrlName, error };
}
