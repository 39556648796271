
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Logo } from 'components/logo';
import { LargeOutlinedButton } from 'components/buttons';


export function TopNav (props: { orgName?: string }) {
  const navigate = useNavigate();
  const { orgName } = props;
  
  useEffect(() => {
  }, []);

  const gotoLogin = () => {
    navigate('/');
  }

  return (
    <header style={{ backgroundColor: '#F5F4EF', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
          <Link to="/">
            <Logo />
          </Link>
      </div>
      <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Welcome {orgName} &nbsp;&nbsp;

            <LargeOutlinedButton text="Login" onClick={ gotoLogin } />

            &nbsp;&nbsp;&nbsp;
          </div>
      </div>
    </header>
  );
}
