
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

import { AuthContext } from 'shared/auth';

import { Invite } from 'types';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Copyright } from 'components/copyright';
import { Logo } from 'components/logo';


const theme = createTheme();

export default function InvitePage () {
  const { setLoggedIn } = useContext(AuthContext);
  const { inviteId } = useParams<{ inviteId: string }>();
  const navigate = useNavigate();
  const auth = useAuth();
  const [ invite, setInvite ] = useState<Invite | undefined>(undefined);

  useEffect(() => {
    if (!inviteId || inviteId.length !== 24) navigate('/');

    const fetchInvite = async () => {
      if (invite) return;
  
      try {
        const ticket = await auth.getInvite(inviteId!);
        if (!ticket) navigate('/');
  
        setInvite(ticket);
      } catch (error) {
        console.log('error:', error);
        navigate('/');
      }
    };

    fetchInvite();
  });

  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const firstName = data.get('firstName') as string;
    const lastName = data.get('lastName') as string;
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      await auth.acceptInvite(inviteId!, firstName, lastName, email, password);

      setLoggedIn(true);

      navigate('/');
    } catch (error) {
      console.log('error:', error);
    }
  };

  if (!invite) return null;

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{   
            backgroundImage: 'url(https://images.unsplash.com/photo-1605387201622-8ebb9be0a9ae)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <br />
          <Typography component="h1" variant="h5">
            You've been Invited!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField id="firstName" name="firstName" label="First Name" required fullWidth defaultValue={invite?.firstName} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField id="lastName" name="lastName" label="Last Name" required fullWidth defaultValue={invite?.lastName} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email" name="email" label="Email Address" required fullWidth defaultValue={invite?.email} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="password" name="password" label="Password" type="password" required fullWidth autoFocus />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} fullWidth>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="./login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      </Grid>
    </ThemeProvider>
  );
}