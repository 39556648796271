
import { Copyright } from "components/copyright";

export function Footer(props: any) {
  return (
    <div className='footer'>
      <Copyright />
    </div>
  );
}
  